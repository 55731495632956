/* eslint no-console:0 */


require("trix")
require("@rails/actiontext")
require("@rails/activestorage").start()


// application.js
import Trix from 'trix';

// Import trix's css for simplicity.
import 'trix/dist/trix.css';

// Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

document.addEventListener('trix-initialize', updateToolbars);

window.addEventListener("trix-file-accept", validateUploads);




Trix.config.blockAttributes.alignLeft = {
  tagName: 'align-left',
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignCenter = {
  tagName: 'align-center',
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignRight = {
  tagName: 'align-right',
  nestable: false,
  exclusive: true,
};

class BaseElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }
}

function innerHTML(alignment) {
  return `
    <style>
      :host {
        text-align: ${alignment};
        width: 100%;
        display: block;
      }
    </style>

    <slot></slot>
  `;
}

export class AlignLeftElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML('left');
  }
}

export class AlignCenterElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML('center');
  }
}

export class AlignRightElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML('right');
  }
}

window.customElements.define('align-left', AlignLeftElement);
window.customElements.define('align-center', AlignCenterElement);
window.customElements.define('align-right', AlignRightElement);

function validateUploads(event) {
  console.log('validating')
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only JPG, PNG, or SVG files can be uploaded")
  }
}

function updateToolbars(event) {
  const toolbars = document.querySelectorAll('trix-toolbar')
  // const html = Trix.config.toolbar.getDefaultHTML();

  var alignHtml = toolbarAlignHtml();

  toolbars.forEach((toolbar) => {
    let blockTools = toolbar.querySelector(".trix-button-group--block-tools")
    blockTools.insertAdjacentHTML("afterend", alignHtml)
  })
}


function toolbarAlignHtml() {
  return `
    <span class="trix-button-group trix-button-group--alignment-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>

      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="Align Left" tabindex="-1">Align Center</button>

      <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>
    </span>`
}